<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("unidades") }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
          <v-btn
            v-on:click="abrirJanelaFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.pesquisar.atalho"
            v-on:shortkey="
              !desabilitado && permissaoListar() ? abrirJanelaFiltro() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.pesquisar"
            v-bind:disabled="desabilitado || !permissaoListar()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.pesquisar.icone }} </v-icon
            >{{ lang("pesquisar") }}</v-btn
          >
          <v-btn
            v-on:click="modoIncluir()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.incluir.atalho"
            v-on:shortkey="
              !desabilitado && permissaoIncluir() ? modoIncluir() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.incluir"
            v-bind:disabled="desabilitado || !permissaoIncluir()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.incluir.icone }} </v-icon>
            {{ lang("incluir") }}</v-btn
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <!-- Listar -->
        <v-data-table
          class="elevation-0"
          height="70vh"
          fixed-header
          hide-default-footer
          v-bind:headers="titulo"
          v-bind:items="arrayUnidade.result"
          v-bind:items-per-page="itemPagina"
        >
          <!-- Campo -->
          <template v-slot:item.unidade="{ item }">
            <v-chip v-bind="utilImp.cnt.CONFIG.chip.cor.cinza">{{
              item.unidade
            }}</v-chip>
          </template>
          <template v-slot:item.fusoHorario="{ item }">{{
            utilImp.arrayData.getGmt(item.fusoHorario)
          }}</template>
          <template v-slot:item.horarioEspecial="{ item }">
            <v-icon class="mr-2">{{ iconeAtivo(item.horarioEspecial) }}</v-icon>
          </template>
          <template v-slot:item.dataRegistro="{ item }">{{
            formatarDataHora(item.dataServidor)
          }}</template>
          <!-- Acao Extra -->
          <template v-slot:item.acoes="{ item }">
            <v-icon
              class="mr-2"
              v-bind:title="lang('alterar')"
              v-on:click="modoAlterar(item)"
              v-bind:disabled="desabilitado || !permissaoAlterar()"
              >{{
                utilImp.cnt.CONFIG.icon.alterar +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-icon
              class="mr-2"
              v-bind:title="lang('excluir')"
              v-on:click="modoExcluir(item)"
              v-bind:disabled="desabilitado || !permissaoExcluir()"
              >{{
                utilImp.cnt.CONFIG.icon.excluir +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  v-bind:style="{ cursor: 'pointer' }"
                  v-bind:title="lang('maisAcoes') + '...'"
                  v-bind:disabled="desabilitado || !permissaoAcao()"
                  >{{
                    utilImp.cnt.CONFIG.icon.acaoExtra +
                      " " +
                      utilImp.cnt.CONFIG.icon.tamanho.grande
                  }}</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(itemAcaoExtra, index) in menuAcaoExtra"
                  v-bind:key="index"
                  v-on:click="acaoExtra(item.idUnidade, itemAcaoExtra.value)"
                  v-bind:disabled="desabilitado || itemAcaoExtra.disabled"
                >
                  <v-list-item-title>{{
                    itemAcaoExtra.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-alert v-bind:value="false" color="white" icon="info">{{
              lang("nenhumRegistroEncontrado")
            }}</v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="page"
          v-bind="paginacao"
          v-bind:disabled="desabilitado || !permissaoListar()"
          v-on:input="pesquisar()"
        ></v-pagination>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- Pesquisar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgFiltrar"
      v-on:keydown.esc="validarFecharJanelaFiltro()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("pesquisar") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="validarFecharJanelaFiltro()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namFiltroUnidade"
                  ref="refFiltroUnidade"
                  maxlength="30"
                  v-model="model.filtroUnidade"
                  v-bind:label="lang('unidade')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                  v-on:keyup.enter="pesquisar()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" md="10" />
              <v-col cols="2" sm="2" md="2">
                <v-select
                  style="max-width:100%"
                  v-model="qtdItemPagina"
                  v-bind:items="arrayItemPagina"
                  v-bind:label="lang('itensPorPagina')"
                  v-bind="utilImp.cnt.CONFIG.select"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-btn
            v-on:click.stop="limparFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.limpar.atalho"
            v-on:shortkey="limparFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.limpar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.limpar.icone }}</v-icon
            >{{ lang("limpar") }}</v-btn
          >
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click.stop="validarFecharJanelaFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click.stop="confirmar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.confirmar.atalho"
            v-on:shortkey="dlgFiltrar === true ? confirmar() : null"
            v-bind="utilImp.cnt.CONFIG.btn.confirmar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.confirmar.icone }}</v-icon
            >{{ lang("confirmar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Salvar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgSalvar"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.salvar
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline"
            >{{ cmpTituloAcao }} {{ lang("unidade") }}</span
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaSalvar(false)">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namUnidade"
                  ref="refUnidade"
                  maxlength="50"
                  v-model="itemEditado.unidade"
                  v-bind:label="lang('unidade') + utilImp.cnt.CAMPO.obrigatorio"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  name="namFusoHorario"
                  ref="refFusoHorario"
                  v-model="itemEditado.fusoHorario"
                  v-bind:label="
                    lang('fusoHorario') + utilImp.cnt.CAMPO.obrigatorio
                  "
                  v-bind:items="utilImp.arrayData.gmt"
                  item-value="value"
                  item-text="text"
                  v-bind="utilImp.cnt.CONFIG.select"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  name="namHorarioEspecial"
                  ref="refHorarioEspecial"
                  v-model="itemEditado.horarioEspecial"
                  v-bind:label="lang('horarioEspecial')"
                  true-value="1"
                  false-value="0"
                  v-bind="utilImp.cnt.CONFIG.checkbox"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namEndereco"
                  ref="refEndereco"
                  maxlength="150"
                  v-model="itemEditado.endereco"
                  v-bind:label="lang('endereco')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  name="namAnotacao"
                  ref="refAnotacao"
                  maxlength="200"
                  rows="3"
                  auto-grow
                  v-model="itemEditado.anotacao"
                  v-bind:label="lang('anotacao')"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="fecharJanelaSalvar(false)"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon>
            {{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
            v-on:shortkey="dlgSalvar === true ? salvar() : null"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon>
            {{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "../../../util/Util.js";

const ACAO_INCLUIR = "acaoIncluir";
const ACAO_ALTERAR = "acaoAlterar";
const ACAO_LISTAR = "acaoListar";
const ACAO_FECHAR_JANELA_FILTRO = "acaoFecharJanelaFiltro";
const ACAO_EXTRA_VERIFICAR_DATA_HORA_LOCAL = "acaoExtraVerificarDataHoraLocal";
const ITEM_PAGINA = 5000; // v-data-table
const ARRAY_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.qtd;
const POSICAO_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.posicao.p1;

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],

  data: () => ({
    utilImp: util,
    dlgFiltrar: false,
    dlgSalvar: false,
    titulo: [],
    arrayUnidade: [],
    menuAcaoExtra: [],
    desabilitado: false,
    page: 1,
    itemPagina: ITEM_PAGINA,
    arrayItemPagina: ARRAY_ITEM_PAGINA,
    qtdItemPagina: ARRAY_ITEM_PAGINA[POSICAO_ITEM_PAGINA - 1],
    paginacao: { length: 1, totalVisible: 5, disabled: false },
    model: { filtroUnidade: "" },
    validacao: {
      pesquisar: {
        unidade: "max:30",
      },
      salvar: {
        unidade: "required|max:50",
        fusoHorario: "required",
        horarioEspecial: "required",
        endereco: "max:150",
        anotacao: "max:200",
      },
    },
    // validacaoPesquisar validacaoSalvar
    indice: -1,
    itemEditado: {
      idUnidade: null,
      unidade: "",
      fusoHorario: "",
      horarioEspecial: "",
      endereco: "",
      anotacao: "",
    },
    itemPadrao: {
      idUnidade: null,
      unidade: "",
      fusoHorario: util.cnt.GMT_PADRAO,
      horarioEspecial: util.cnt.BOLEANO.sim,
      endereco: "",
      anotacao: "",
    },
  }),

  computed: {
    cmpTituloAcao() {
      return this.indice === -1 ? this.lang("incluir") : this.lang("alterar");
    },
  },

  watch: {},

  created() {
    this.iniciar();
  },

  mounted() {
    this.pesquisar();
  },

  methods: {
    iniciar() {
      this.titulo = [
        // align: 'start' | 'center' | 'end'
        {
          text: this.lang("unidade"),
          align: "start",
          value: "unidade",
          sortable: true,
          divider: false,
        },
        {
          text: this.lang("endereco"),
          align: "start",
          value: "endereco",
          sortable: true,
        },
        {
          text: this.lang("anotacao"),
          align: "start",
          value: "anotacao",
          sortable: false,
        },
        {
          text: this.lang("fusoHorario"),
          align: "start",
          value: "fusoHorario",
          sortable: true,
        },
        {
          text: this.lang("horarioEspecial"),
          align: "center",
          value: "horarioEspecial",
          sortable: true,
        },
        {
          text: this.lang("dataRegistro"),
          align: "center",
          value: "dataRegistro",
          sortable: true,
        },
        {
          text: this.lang("acoes"),
          align: "center",
          value: "acoes",
          sortable: false,
        },
      ];
      // Menu de opção extra
      this.menuAcaoExtra.push({
        title: this.lang("verificarHorarioLocal"),
        value: ACAO_EXTRA_VERIFICAR_DATA_HORA_LOCAL,
        disabled: !this.permissaoAcao(),
      });
    },

    modoIncluir() {
      this.indice = -1;
      this.itemEditado = Object.assign({}, this.itemPadrao);
      this.abrirJanelaSalvar();
      this.setFocusUnidade();
    },

    modoAlterar(item) {
      this.indice = this.arrayUnidade.result.indexOf(item);
      this.itemEditado = Object.assign({}, item);
      this.abrirJanelaSalvar();
      this.setFocusUnidade();
    },

    modoExcluir(item) {
      this.confirmDelete(this.lang("excluirUnidade"), item.unidade).then(
        result => {
          if (result.value) {
            this.excluir(item);
          }
        }
      );
    },

    salvar() {
      let acao = "";
      if (this.indice > -1) {
        // Alterar
        acao = ACAO_ALTERAR;
      } else {
        // Incluir
        acao = ACAO_INCLUIR;
      }
      this.validarSalvar(acao);
    },

    async validarSalvar(acao) {
      let result = "";
      result = await util.val.validate(
        this.itemEditado.unidade,
        this.validacao.salvar.unidade
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("unidade"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.fusoHorario,
        this.validacao.salvar.fusoHorario
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("fusoHorario"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.horarioEspecial,
        this.validacao.salvar.horarioEspecial
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("horarioEspecial"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.endereco,
        this.validacao.salvar.endereco
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("endereco"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.anotacao,
        this.validacao.salvar.anotacao
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("anotacao"))
        );
        return;
      }
      // Direcionar para inclusão
      if (acao === ACAO_INCLUIR) {
        this.incluir();
      }
      // Direcionar para alteração
      if (acao === ACAO_ALTERAR) {
        this.alterar();
      }
    },

    incluir() {
      this.desabilitar(true);
      util.srv.unidade
        .incluirUnidade(this.itemEditado)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    alterar() {
      this.desabilitar(true);
      util.srv.unidade
        .alterarUnidade(this.itemEditado)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    excluir(item) {
      this.desabilitar(true);
      util.srv.unidade
        .excluirUnidade(item.idUnidade)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.pesquisar();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    confirmar() {
      this.limparPaginador();
      this.pesquisar();
    },

    pesquisar() {
      if (this.dlgFiltrar) {
        // Caso a janela filtrar esteja aberta, ocorrera a validacao.
        this.validarPesquisar(ACAO_LISTAR);
      } else {
        this.listar();
      }
    },

    async validarPesquisar(acao) {
      let result = "";
      result = await util.val.validate(
        this.model.filtroUnidade,
        this.validacao.pesquisar.unidade
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("unidade"))
        );
        return;
      }
      // Direcionar para listar
      if (acao === ACAO_LISTAR) {
        this.listar();
      }
      // Direcionar para fechar a janela filtro
      if (acao === ACAO_FECHAR_JANELA_FILTRO) {
        this.fecharJanelaFiltro();
      }
    },

    listar() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      util.srv.unidade
        .listarUnidade(this.model.filtroUnidade, this.pagina(), this.limite())
        .then((response) => {
          this.distribuir(response.data);
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },

    distribuir(arrayRegistro) {
      if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        if (arrayRegistro.result !== null) {
          this.arrayUnidade = arrayRegistro;
          this.paginador();
        } else {
          // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
        }
      } else {
        this.alertWarning(this.langResultCode(arrayRegistro.resultCode));
      }
    },

    acaoExtra(idUnidade, opcao) {
      if (opcao === ACAO_EXTRA_VERIFICAR_DATA_HORA_LOCAL) {
        this.verificarDataHoraLocal(idUnidade);
      }
    },

    verificarDataHoraLocal(idUnidade) {
      this.desabilitar(true);
      util.srv.unidade
        .verificarHorario(idUnidade)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.alertInfo(response.data.result);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    abrirJanelaFiltro() {
      this.dlgFiltrar = true;
      this.setFocusFiltroUnidade();
    },
    abrirJanelaSalvar() {
      this.dlgSalvar = true;
    },

    fecharJanelaFiltro() {
      if (!this.desabilitado) {
        this.dlgFiltrar = false;
      }
    },
    fecharJanelaSalvar(passeLivre) {
      if (!passeLivre) {
        if (!this.desabilitado) {
          this.dlgSalvar = false;
        }
      } else {
        this.dlgSalvar = false;
      }
    },
    /* Metodo responsavel por fechar a janela de pesquisa somente se os filtros forem validos */
    /* Necessario para que o paginador não execute a consulta com parametros invalidos */
    validarFecharJanelaFiltro() {
      this.validarPesquisar(ACAO_FECHAR_JANELA_FILTRO);
    },

    limparLista() {
      this.arrayUnidade = [];
    },
    limparFiltro() {
      this.model.filtroUnidade = "";
      this.setFocusFiltroUnidade();
    },
    limparItemEditado() {
      this.itemEditado = Object.assign({}, this.itemPadrao);
    },
    limparPaginador() {
      this.page = 1;
      this.paginacao.length = 1;
    },

    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },

    setFocusFiltroUnidade() {
      setTimeout(() => {
        this.$refs.refFiltroUnidade.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    setFocusUnidade() {
      setTimeout(() => {
        this.$refs.refUnidade.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    iconeAtivo: function(valor) {
      return valor === util.cnt.BOLEANO.sim
        ? util.cnt.CONFIG.icon.sim + " " + util.cnt.CONFIG.icon.tamanho.grande
        : util.cnt.CONFIG.icon.nao + " " + util.cnt.CONFIG.icon.tamanho.grande;
    },

    paginador() {
      let contRegistro = this.arrayUnidade.result.length;
      if (contRegistro >= this.qtdItemPagina) {
        this.paginacao.length = this.page + 1;
      }
    },

    pagina: function() {
      return this.page - 1;
    },

    limite: function() {
      return this.qtdItemPagina;
    },

    permissaoListar() {
      return this.verificarPermissao(util.prm.P_1006);
    },
    permissaoIncluir() {
      return this.verificarPermissao(util.prm.P_1007);
    },
    permissaoAlterar() {
      return this.verificarPermissao(util.prm.P_1008);
    },
    permissaoExcluir() {
      return this.verificarPermissao(util.prm.P_1009);
    },
    permissaoAcao() {
      return this.verificarPermissao(util.prm.P_1010);
    },
  },
};
</script>
<style>
/* .v-text-field input{
  background-color: grey
}
.v-text-field textarea{
  background-color: grey
} */
</style>
